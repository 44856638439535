<template>
  <div>
    <div class="flex flex-col">
      <div class="w-full">
        <div>
          <vue-good-table
            ref="acEmailLogTable"
            styleClass="vgt-table"
            row-style-class="text-sm"
            :columns="columns"
            :rows="email_logs"
            :pagination-options="{
              enabled: true,
              perPage: 20,
              perPageDropdown: [20, 50, 100, 400],
              dropdownAllowAll: false,
            }"
            :sort-options="{
              enabled: false,
              multipleColumns: true,
              initialSortBy: serverParams.sort,
            }"
            :line-numbers="true"
            mode="remote"
            @on-page-change="onPageChange"
            @on-sort-change="onSortChange"
            @on-column-filter="onColumnFilter"
            @on-per-page-change="onPerPageChange"
            :totalRows="totalRecords"
            :isLoading.sync="isLoading"
          >
            <template slot="loadingContent">
              <div class="text-center text-2xl text-primary-500"><BaseIcon icon="spinner" spin /></div>
            </template>
            <div slot="table-actions">
              <button class="btn-secondary-outline mx-1" @click="resetTable()">
                {{ $t("reset") }}
                <BaseIcon icon="redo" class="ml-1" />
              </button>
              <button class="btn-blue mx-1" @click="exportXLS()">
                {{ $t("export") }}
                <BaseIcon icon="file-excel" class="ml-1" />
              </button>
            </div>
            <template slot="table-row" slot-scope="props">
              <span v-if="props.column.field == 'customer_id'">{{ props.row.customer_name }}</span>
              <span v-else-if="props.column.field == 'alarm_center_id'">{{ props.row.alarm_center_name }}</span>
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>
          </vue-good-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AlarmCenterEmailLog",
  title() {
    return `Larmunderlag`;
  },
  data() {
    return {
      type: this.$route.params.type,
      id: this.$route.params.id,
      isLoading: false,
      totalRecords: 0,
      email_logs: [],
      customers: null,
      alarm_centers: null,
      reseller: null,
      serverParams: {
        filters: {},
        sort: [{ field: "createdAt", type: "desc" }],
        page: 1,
        perPage: 20,
      },
    };
  },

  computed: {
    user() {
      return this.$store.state.user;
    },
    columns() {
      return [
        { label: this.$t("alarm_instruction.customer"), field: "customer_id", filterOptions: { enabled: false, placeholder: "Kund", filterDropdownItems: [] } },
        { label: this.$t("alarm_instruction.unit_name"), field: "unit_name" },
        { label: this.$t("alarm_instruction.alarm_name"), field: "alarm_name" },
        { label: this.$t("alarm_instruction.serial_number"), field: "serial_number" },
        { label: this.$t("alarm_instruction.agreement"), field: "agreement_number", sortable: true },
        { label: this.$t("alarm_instruction.alarm_center"), field: "alarm_center_id", filterOptions: { enabled: false, placeholder: "Larmcentral", filterDropdownItems: [] } },
        { label: this.$t("alarm_instruction.user"), field: "user", sortable: true },
        { label: this.$t("alarm_instruction.last_updated"), field: "createdAt", type: "date", formatFn: this.formatDate, sortable: true },
      ];
    },
  },

  methods: {
    async getAlarmCenterEmailLog() {
      try {
        let response = await this.axios.post(`${process.env.VUE_APP_SERVER_URL}/alarm-center/email-log`, this.serverParams);
        this.email_logs = response.data.email_logs;
        this.totalRecords = response.data.totalRecords;
      } catch (error) {
        this.handleError(error);
      }
    },

    getListCustomer() {
      this.axios
        .get(`${process.env.VUE_APP_SERVER_URL}/list/customer`)
        .then((response) => {
          this.customers = response.data.customers;
          let customer_names = _.map(this.customers, function(o) {
            return { value: o._id, text: o.name };
          });
          let customer_col = _.find(this.columns, { field: "customer_id" });
          customer_col.filterOptions.filterDropdownItems = customer_names;
          customer_col.filterOptions.enabled = true;
        })
        .catch((error) => {
          this.handleError(error);
        });
    },

    getListAlarmCenter() {
      this.axios
        .get(`${process.env.VUE_APP_SERVER_URL}/list/alarm-center`)
        .then((response) => {
          this.alarm_centers = response.data.alarm_centers;
          let alarmcenter_names = _.map(this.alarm_centers, function(o) {
            return { value: o._id, text: o.name };
          });
          let alarmcenter_col = _.find(this.columns, { field: "alarm_center_id" });
          alarmcenter_col.filterOptions.filterDropdownItems = alarmcenter_names;
          alarmcenter_col.filterOptions.enabled = true;
        })
        .catch((error) => {
          this.handleError(error);
        });
    },

    exportXLS() {
      let export_columns = [];

      this.email_logs.forEach((log) => {
        export_columns.push({
          Kund: log.customer_name,
          Verksamhetsnamn: log.unit_name,
          Larm: log.alarm_name,
          Serienummer: log.serial_number,
          Avtal: log.agreement_number,
          Larmcentral: log.alarm_center_name,
          "Senast uppdaterat": this.formatDate(log.createdAt),
        });
      });

      this.excelExport(export_columns, "Larmunderlag", "larmunderlag_rapport");
    },

    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.getAlarmCenterEmailLog();
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage, page: 1 });
      this.getAlarmCenterEmailLog();
    },

    onSortChange(params) {
      this.updateParams({ sort: params });
      this.getAlarmCenterEmailLog();
    },

    onColumnFilter(params) {
      let filters = params.columnFilters;

      for (const key in filters) {
        if (Object.hasOwnProperty.call(filters, key)) {
          if (filters[key] == "") delete filters[key];
        }
      }

      this.updateParams({ filters, page: 1 });
      this.getAlarmCenterEmailLog();
    },

    resetTable() {
      this.serverParams.filters = {};
      this.serverParams.sort = { field: "createdAt", type: "desc" };
      this.serverParams.page = 1;
      this.$refs["acEmailLogTable"].globalSearchTerm = "";
      this.$refs["acEmailLogTable"].reset();
    },
  },

  created() {
    if (this.user.role == "admin" || this.user.role == "seller") {
      this.getListCustomer();
      this.getListAlarmCenter();
    }

    this.setPageTitle(`Larmunderlag`, "alarm_center_email_log");
  },
};
</script>
