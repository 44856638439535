var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"flex flex-col"},[_c('div',{staticClass:"w-full"},[_c('div',[_c('vue-good-table',{ref:"acEmailLogTable",attrs:{"styleClass":"vgt-table","row-style-class":"text-sm","columns":_vm.columns,"rows":_vm.email_logs,"pagination-options":{
            enabled: true,
            perPage: 20,
            perPageDropdown: [20, 50, 100, 400],
            dropdownAllowAll: false,
          },"sort-options":{
            enabled: false,
            multipleColumns: true,
            initialSortBy: _vm.serverParams.sort,
          },"line-numbers":true,"mode":"remote","totalRows":_vm.totalRecords,"isLoading":_vm.isLoading},on:{"on-page-change":_vm.onPageChange,"on-sort-change":_vm.onSortChange,"on-column-filter":_vm.onColumnFilter,"on-per-page-change":_vm.onPerPageChange,"update:isLoading":function($event){_vm.isLoading=$event},"update:is-loading":function($event){_vm.isLoading=$event}},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'customer_id')?_c('span',[_vm._v(_vm._s(props.row.customer_name))]):(props.column.field == 'alarm_center_id')?_c('span',[_vm._v(_vm._s(props.row.alarm_center_name))]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])},[_c('template',{slot:"loadingContent"},[_c('div',{staticClass:"text-center text-2xl text-primary-500"},[_c('BaseIcon',{attrs:{"icon":"spinner","spin":""}})],1)]),_c('div',{attrs:{"slot":"table-actions"},slot:"table-actions"},[_c('button',{staticClass:"btn-secondary-outline mx-1",on:{"click":function($event){return _vm.resetTable()}}},[_vm._v(" "+_vm._s(_vm.$t("reset"))+" "),_c('BaseIcon',{staticClass:"ml-1",attrs:{"icon":"redo"}})],1),_c('button',{staticClass:"btn-blue mx-1",on:{"click":function($event){return _vm.exportXLS()}}},[_vm._v(" "+_vm._s(_vm.$t("export"))+" "),_c('BaseIcon',{staticClass:"ml-1",attrs:{"icon":"file-excel"}})],1)])],2)],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }